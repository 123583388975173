// ToggleSwitch.js\
import './ToggleSwitch.css';

const ToggleSwitch = ({ gpt4, setGpt4, setNotificationShown, setNotificationMessage}) => {
    const handleChange = async (e) => {
    setGpt4(e.target.checked);
    if (e.target.checked === true) {
        setGpt4(true);
        setNotificationShown(true);
        localStorage.setItem("gpt4", "true");
        setNotificationMessage(`Now Using GPT4!`);
    } else {
        setGpt4(false);
        localStorage.setItem("gpt4", "false");
        setNotificationMessage(`Now Using ChatGPT`);
        }
    };

return (
    <div className="toggle-wrapper">
      <span className="label-text">
        ChatGPT
        <i className="info-icon fas fa-info-circle" style={{ position: 'relative' }}>
          <span className="tooltip-text">ChatGPT model is fast and reliable</span>
        </i>
      </span>
      <div className="toggle-container">
        <label className="toggle-switch">
          <input type="checkbox" checked={gpt4} onChange={handleChange} />
          <span className="toggle-slider"></span>
        </label>
      </div>
      <span className="label-text">
        GPT4
        <i className="info-icon fas fa-info-circle">
          <span className="tooltip-text">GPT4 can take 2X more input and is much more intelligent - Use GPT4 Cheaper & Faster with FixThisCode</span>
        </i>
      </span>
    </div>
  );
};
export default ToggleSwitch;
