import React, { useState, useRef, useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import useMediaQuery from './useMediaQuery';
import ToggleSwitch from './ToggleSwitch';
import { darkStyles, lightStyles } from './styles.js';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { SignUpForm, SignInForm, signUserOut, getUserData, update, auth, onAuthStateChanged  } from './FirebaseFuncs';
import ChatContainer from './ftccore.js'
import JSZip from 'jszip';
import { pdfjs } from 'react-pdf';
import Tesseract from 'tesseract.js';
import heic2any from "heic2any";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const ChatContainerRef = useRef(null); // create a reference using the useRef hook
  const isMobile = useMediaQuery(768 * 2); // Adjust the breakpoint value as needed
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [userCredits, setUserCredits] = useState(100);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationShown, setNotificationShown] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userRefdb, setUserRefdb] = useState(null);
  const [userData, setUserData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [inputValue, setInputValue] = useState('demo');
  const [Ampersandposium, setAmpersandposium] = useState('Demo');
  const [siteUrl, setSiteUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [customDataVisible, setCustomDataVisible] = useState(false);
  const [customData, setCustomData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [gpt4, setGpt4] = useState(false);
  const [display, setDisplay] = useState(false);
  const [database, setDatabase] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [prompt, setPrompt] = useState(`Use the following Context to answer the Question at the end. 
Answer as if you were the modern voice of the context, without referencing the context or mentioning 
the fact that any context has been given. Make sure to not just repeat what is referenced. Don't preface or give any warnings at the end.

Chat History (if any): {history}

Additional Context: {context}

Question: {content}

(You are a sales agent for DRI, and you are interacting with users through a website chat interface. Make your messages optimized for conversation.) 
Answer:`);


  useEffect(() => {
    // Define an async function to await getUserData
    const fetchData = async () => {
      const { userRef, userData, currentUser } = await getUserData();

      // Use the set functions from useState to store these values
      setUserRefdb(userRef);
      setUserData(userData);
      setCurrentUser(currentUser);
    }

    // Call the async function
    fetchData();
  }, []);

  
  const MainComponent = () => {
    const routerLocation = useLocation();

    useEffect(() => {
        const pathSegments = routerLocation.pathname.split('/').filter(segment => segment !== '');

        // First segment is the vaultName
        const vaultName = pathSegments[0];
        setAmpersandposium(vaultName);

        // Check for the "show" segment
        if (pathSegments.includes('show')) {
            setDisplay(true);
            console.log('display:', display);
        } else {
            setDisplay(false);
            console.log('display:', display);
        }

        // Check for the "data" segment
        if (pathSegments.includes('data')) {
            setShowDelete(true);
            console.log('database:', database);
          } else {
            setShowDelete(false);
            console.log('database:', database);
        }
    }, [routerLocation.pathname]);
  };


  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Notification = ({ message, notificationShown, setNotificationShown }) => {
    const [isVisible, setIsVisible] = useState(true);
  
    useEffect(() => {
      if (notificationShown) {
        const timeoutId = setTimeout(() => {
          setIsVisible(false);
          setNotificationShown(false); // Reset the notificationShown state when the notification is hidden
        }, 5000);
  
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }, [notificationShown, setNotificationShown]);
  
    if (!isVisible || !notificationShown) {
      return null;
    }
  
    return (
      <div style={styles.notificationStyle}>
        {message}
      </div>
    );
  };


  async function convertHEICToJPEG(heicFile) {
      const convertedBlob = await heic2any({
          blob: heicFile,
          toType: "image/jpeg",
      });
      return convertedBlob;
  }

  async function fetchUserCredits() {
      const { userData, currentUser } = await getUserData();

      if (currentUser) {
        const displayCreds = Math.round((userData.credits) * 100) / 100;
          setUserCredits(displayCreds);
      }
    }
  
  async function extractTextFromImage(blob) {
      const { data: text } = await Tesseract.recognize(blob, 'eng');
      return text.text;
  }
    
  async function extractTextFromPDFUsingOCR(data) {
      const pdf = await pdfjs.getDocument({ data }).promise;
      let allText = "";
  
      for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 3 });
  
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;
  
          await page.render({
              canvasContext: ctx,
              viewport: viewport
          }).promise;
  
          const { data: text } = await Tesseract.recognize(canvas, 'eng');
          allText += text.text;
      }
  
      return allText;
  }

  async function extractTextFromDocx(arrayBuffer) {
      const zip = new JSZip();
      const content = await zip.loadAsync(arrayBuffer);
      const docXML = await content.files["word/document.xml"].async("text");
      const parser = new DOMParser();
      const docNode = parser.parseFromString(docXML, "application/xml");

      const nsResolver = (prefix) => {
          return {
              'w': 'http://schemas.openxmlformats.org/wordprocessingml/2006/main'
          }[prefix] || null;
      };

      const textNodeResults = docNode.evaluate('//w:t', docNode, nsResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

      let textContentArray = [];
      for (let i = 0; i < textNodeResults.snapshotLength; i++) {
          textContentArray.push(textNodeResults.snapshotItem(i).textContent);
      }
      
      return textContentArray.join(" ");
  }

  async function handleFileUpload(event) {
      const file = event.target.files[0];
      console.log('file type:', file.type)
      setIsLoading(true);

      const reader = new FileReader();

      reader.onload = async function(event) {
        let fileContent;

        switch(file.type) {
            case 'text/plain': // .txt
            case 'text/csv':   // .csv
            case 'text/markdown': // .md
              fileContent = event.target.result;
              break;
            case 'application/pdf': // .pdf
                fileContent = await extractTextFromPDFUsingOCR(event.target.result);
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
                fileContent = await extractTextFromDocx(event.target.result);
                break;
            case 'image/heic':
                const jpegBlob = await convertHEICToJPEG(file);
                fileContent = await extractTextFromImage(jpegBlob);
                break;
            case 'image/jpeg':
            case 'image/png':
                console.log('Extracting text from image...');
                const extractedText = await extractTextFromImage(file);
                setCustomData(extractedText);  // Set the extracted text to the customData state
                setCustomDataVisible(true);    // Show the custom data text field for user review and edits
                break;  // Return early to avoid sending data directly to the database
            default:
                setIsLoading(false);
                alert("Unsupported file type.");
                return;
        }

        fetch('https://api.vectorvault.io/add_cloud', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                vault: Ampersandposium,
                user: process.env.REACT_APP_VV_USER,
                api_key: process.env.REACT_APP_VV_API,
                openai_key: process.env.REACT_APP_OPEN_API_KEY,
                text: fileContent,
                name: file.name,
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.error("Error during fetch:", error);
        })
        .finally(() => {
            event.target.value = null;
            setIsLoading(false); // Hide spinner
            setReloadData(prevState => !prevState); // Reload data
            if (file.type.startsWith('image/')){
              alert("Text successfully extracted.")
            } else {
              alert("File uploaded successfully.")
            }
        });
    };
    // Determine how to read the file based on its type
    if (file.type === 'text/plain' || file.type === 'text/csv' || file.type === 'text/markdown') {
        reader.readAsText(file);
    } else if (file.type === 'application/pdf' || 
               file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
               file.type.startsWith('image/')) {  // For all image types, including heic, jpeg, png
        reader.readAsArrayBuffer(file);
    } else {
        setIsLoading(false);
        alert("Unsupported file type.");
    }
  };

  
  function handleCustomDataSave(customDataContent) {
      setIsLoading(true); 

      fetch('https://api.vectorvault.io/add_cloud', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              vault: Ampersandposium,
              user: process.env.REACT_APP_VV_USER,
              api_key: process.env.REACT_APP_VV_API,
              openai_key: process.env.REACT_APP_OPEN_API_KEY,
              text: customDataContent, // Send the textarea content here
              name: "custom entry", // Send the textarea content here
          })
      })
      .then(response => response.json())
      .then(data => {
          console.log(data);
          // Do anything else you need with the response data here
      })
      .catch(error => {
          console.error("There was an error with the fetch operation:", error);
      })
      .finally(() => {
          setIsLoading(false); // Hide spinner when the fetch is complete
          setCustomData('')
          setReloadData(prevState => !prevState); // Reload data
          alert("Data uploaded successfully.")
      });
  }

  
  function handleSiteUpload() {
      setIsLoading(true); // Show spinner

      fetch('https://api.vectorvault.io/add_site', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              vault: Ampersandposium,
              user: process.env.REACT_APP_VV_USER,
              api_key: process.env.REACT_APP_VV_API,
              openai_key: process.env.REACT_APP_OPEN_API_KEY,
              site: siteUrl, 
              name: siteUrl, 
          })
      }).then(response => response.json()).then(data => {
          console.log(data);
          setSiteUrl(''); // Clear the input
      }).catch(error => {
          console.error("There was an error with the fetch operation:", error);
      }).finally(() => {
          setSiteUrl('');
          setIsLoading(false); // Hide spinner
          setReloadData(prevState => !prevState); // Reload data
          alert("Site uploaded successfully.")
      });
  }

  const handleDelete = () => {
    setShowModal(true); // Show the modal when the delete button is clicked
  }

  const confirmDeletion = () => {
      if (inputCode === "789") {
          setIsLoading(true); // Show spinner
          setShowModal(false); // Hide the modal once the operation is done
          fetch('https://api.vectorvault.io/delete_vault', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  vault: Ampersandposium,
                  user: process.env.REACT_APP_VV_USER,
                  api_key: process.env.REACT_APP_VV_API,
              })
          }).then(response => response.json()).then(data => {
              console.log(data);
              alert("Database deleted successfully.");
          }).catch(error => {
              console.error("There was an error with the fetch operation:", error);
          }).finally(() => {
              setIsLoading(false); // Hide spinner
              setReloadData(prevState => !prevState); // Reload data
          });
      } else {
          alert("Incorrect code. Database not deleted.");
          setInputCode(''); // Reset the input for another try
      }
  }


  // This function takes a customer email and product ID and returns whether the customer has an active subscription to the product
  async function paidUserCheck(customerEmail) {
    try {
      // Get the Stripe customer ID associated with the customer email
      const customer = await stripe.customers.list({ email: customerEmail });

      if (customer.data.length === 0) {
        throw new Error('Customer not found');
      }

      const customerId = customer.data[0].id;

      // List the customer's subscriptions for the specified product
      const subscriptions = await stripe.subscriptions.list({
        customer: customerId,
        price: "price_1MrtA9ET5bakQRi3cPK7yw5p", // Change 'items' object to use 'price' instead of 'product'
        status: 'active',
      });
      console.log('Subscriptions:', subscriptions); // Debugging statement

      // Get the most recent invoice for the customer and check its payment status
      const invoices = await stripe.invoices.list({
        customer: customerId,
        limit: 1,
        status: 'paid',
      });
      console.log('Invoices:', invoices); // Debugging statement

      // Return an object with the subscription status and the most recent payment date
      return {
        customerId,
        hasActiveSubscription: subscriptions.data.length > 0,
        lastPaymentDate: invoices.data.length > 0 ? invoices.data[0].created : null,
      };
    } catch (error) {
      console.error('Failed to check subscription status:', error);
      return {
        error: error.message,
        hasActiveSubscription: false,
        lastPaymentDate: null,
      };
    }
  }
  
  async function refreshUserCredits() {
    const refreshButton = document.querySelector(".fa-sync-alt");
    refreshButton.classList.add("spin-fresh");
    setTimeout(() => {
      refreshButton.classList.remove("spin-fresh");
    }, 1000);
    
    const { userRef, userData, currentUser } = await getUserData();
    if (currentUser) {
      await currentUser.reload();
      if (!userData.emailVerified && currentUser.emailVerified) {
        await update(userRef, { emailVerified: true, credits: userData.credits + 500 });
        const displayCreds = Math.round((userData.credits + 500) * 100) / 100;
        setUserCredits(displayCreds);
      } else {
        const displayCreds = Math.round(userData.credits * 100) / 100;
        setUserCredits(displayCreds);
        const paidUser = await paidUserCheck(userEmail);
        const { hasActiveSubscription, lastPaymentDate } = paidUser;
        const isActiveInDb = userData.isActive || false;
        const lastPaymentDateInDb = userData.paymentDate || 0;
        
        if (hasActiveSubscription) {
          if (!isActiveInDb) {
            // Update the user in the database as an active paying user
            await update(userRef, {
              isActive: true,
              paymentDate: lastPaymentDate,
              credits: 15000,
              creditUpdateDate: Date.now(),
            });
            setNotificationShown(true);
            setNotificationMessage(`Account Updated: Subscription Started - New Credits Added!`);
            setUserCredits(15000);        
          } else if (lastPaymentDate > lastPaymentDateInDb) {
            await update(userRef, {
              isActive: true,
              paymentDate: lastPaymentDate,
              credits: 15000,
              creditUpdateDate: Date.now(),
            });
            setNotificationShown(true);
            setNotificationMessage(`Account Updated: New Payment - New Credits Added!`);
            setUserCredits(15000);        
          } else {
            // Do not add credits
            setNotificationShown(true);
            setNotificationMessage(`Refreshed!`);
          }
        } else {
          if (isActiveInDb) {
            // Update the user in the database as a non-active paying user
            await update(userRef, { isActive: false });
          }
          setNotificationShown(true);
          setNotificationMessage(`Refreshed`);
          const displayCreds = Math.round(userData.credits * 100) / 100;
          setUserCredits(displayCreds);
        }
      }
    }
  }

  const handleInputSubmit = () => {
      window.location.href = 'http://byobbot.com/?&' + encodeURIComponent(inputValue);
      setAmpersandposium(inputValue);  // update the Ampersandposium variable
      console.log('Ampersandposium updated to:', inputValue);
      setInputValue('')
  };
  
  // Load the state from local storage when the component mounts
  useEffect(() => {
    const storedIsDarkMode = localStorage.getItem("isDarkMode");
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    const storedChatHistory = localStorage.getItem("chatHistory") || "[]";
    const showUpgrade = localStorage.getItem("showUp") || "false";

    if (storedIsDarkMode) {
      setIsDarkMode(storedIsDarkMode === "true");
    }

    if (storedIsLoggedIn) {
      setIsLoggedIn(storedIsLoggedIn === "true");
    }
    setShowUpgradeMessage(showUpgrade === "true");

    // Load the chat history from local storage
    try {
      const parsedChatHistory = JSON.parse(storedChatHistory);
      setChatHistory(parsedChatHistory);
    } catch (error) {
      console.error("Failed to parse stored chat history:", error);
    }

    // Add an observer for the auth state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        saveIsLoggedIn(true);
        setUserEmail(user.email); // Store the user's email
        fetchUserCredits();
      } else {
        saveIsLoggedIn(false);
        setUserEmail(''); // Clear the email when the user is signed out
      }
    });

    // Clean up the observer on component unmount
    return () => {
      unsubscribe();
    };
  }, []);

  // Update setIsLoggedIn to store the state in local storage
  const saveIsLoggedIn = (newState) => {
    setIsLoggedIn(newState);
    localStorage.setItem("isLoggedIn", newState);
  };

  function openSignUp() {
    setShowSignUp(true);
    setShowSignIn(false);
  }
  
  function openSignIn() {
    setShowSignIn(true);
    setShowSignUp(false);
  }

  // const toggleTheme = () => {
  //   setIsDarkMode(newState);
  //   localStorage.setItem("isDarkMode", newState);
  // };

  document.addEventListener("touchstart", function() {
    if (document.body.style.zoom !== "1") {
      document.body.style.zoom = 1;
    }
  });

  const styles = isDarkMode ? darkStyles : lightStyles;
  return (  <Router>
    <div style={styles.wrapper}>
        <div className="App" style={styles.body}>
            
                <MainComponent/>

            <h1 style={{ ...styles.h1, textAlign: "left", fontSize: isMobile ? "1.5rem" : "2.9rem", display: "flex", alignItems: "left", marginTop: isMobile ? "10px" : "8px"}}>
                <img src={logo} className="App-logo" alt="logo" style={{height: isMobile ? "3.3rem" : "4.5rem", marginRight: "1rem", marginTop: isMobile ? "-10px" : "-3px"}} />
                {Ampersandposium}
            </h1>
            <div style={{marginTop:"20px"}}>
            {display && (
                <>
                {!showDelete && ( <> <div style={{marginTop: "-40px"}}></div> </>)}
                {showDelete && (
                  <>
                  <div style={{marginTop: "40px"}}></div>
                    <ToggleSwitch gpt4={gpt4} setGpt4={setGpt4} setNotificationMessage={setNotificationMessage} setNotificationShown={setNotificationShown} />
                    <br />
                    <a style={{fontSize: ".9rem"}}>.txt .pdf .csv .docx files: </a>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                    <button style={{fontSize: ".9rem", marginBottom: "0px", backgroundColor: "transparent", border: "none", textDecoration: "underline", cursor: "pointer", color: "blue"}}
                        onClick={(e) => { e.preventDefault(); document.getElementById('fileInput').click(); }}>Upload</button>
                    <div>
                        <a style={{fontSize: ".9rem"}}>Upload website: </a>
                        <input
                            type="text"
                            value={siteUrl}
                            onChange={(e) => setSiteUrl(e.target.value)}
                            placeholder="Enter website URL"
                        />
                        <button onClick={handleSiteUpload}>Submit</button>
                    </div>
                  </>
                )}
                            {/* Loading Spinner */}
                            <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                                <svg viewBox="0 0 50 50">
                                    <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                                </svg>
                            </div>
                        <div>
                            {showModal && (
                                <div className="modal">
                                    <div className="modal-content" style={{paddingLeft: "15px", paddingTop: "10px"}}>
                                        <a>Delete every item in the database? Enter 789 to proceed:</a><br />
                                        <input 
                                            type="text" 
                                            value={inputCode}
                                            onChange={(e) => setInputCode(e.target.value)}
                                        />
                                        <button style={{marginTop: "10px"}} onClick={confirmDeletion}>Confirm</button>
                                        <button onClick={() => setShowModal(false)}>Cancel</button>
                                        <br /><br />
                                    </div>
                                </div>
                            )}
                            <br />
                            {showDelete && (
                              <>
                            <button style={{marginTop: "10px", marginBottom: "5px"}} onClick={() => setIsVisible(!isVisible)}>
                                {isVisible ? 'Hide' : 'Show'} Prompt
                            </button>
                            {isVisible && (
                                <>
                                    <textarea
                                      placeholder={prompt}
                                      id="chat-message"
                                      rows={isMobile ? 20 : 13}
                                      style={{ ...styles.textarea, marginLeft: "-8px", paddingRight: "6px", paddingLeft: "8px" }}
                                      value={prompt}
                                      onChange={(e) => setPrompt(e.target.value)}
                                    />
                                </>
                            )}
                                <button onClick={() => setCustomDataVisible(!customDataVisible)}>
                                    {customDataVisible ? 'Hide' : 'Show'} Text Field
                                </button>
                              </>
                            )}
                            {customDataVisible && (
                                <>
                                    <textarea
                                        placeholder={`Type or paste custom data to save to the vector database here...`}
                                        id="chat-message"
                                        rows={isMobile ? 20 : 15}
                                        style={{ ...styles.textarea, marginLeft: "-8px", paddingRight: "6px", paddingLeft: "8px"}}
                                        value={customData}
                                        onChange={(e) => setCustomData(e.target.value)}
                                    />
                                    <button className="cool-button" onClick={() => handleCustomDataSave(customData)}>Save to database </button>
                                </>
                            )}
                        </div>
                        <br></br>
                    </>
                )}
        </div>
        <br></br>
        {/* <button onClick={toggleTheme} style={{ ...styles.toggleButton, marginLeft: "-10px", marginTop: "0px", alignContent:  isMobile ? "center" : "left"}}>
        {isDarkMode ? "Use Light Mode" : "Use Dark Mode"}
      </button> */}
      {isLoggedIn && (
        <div className={styles.userCredits}>
          Credits: {userCredits}{" "}
          <button onClick={refreshUserCredits} className="link-button" style={styles.refresh}><i className="fas fa-sync-alt"></i></button>
        </div>
      )}  
      <div className="centered-container">
        {!isLoggedIn && (
          <>
            {/* <button className="link-button" onClick={openSignUp}>
              Sign Up
            </button>
            <span className="separator">|</span>
            <button className="link-button" onClick={openSignIn}>
              Sign In
            </button> */}
          </>
        )}
        {isLoggedIn && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '14px', marginTop: "-30px"}}>
            <div>{userEmail}</div>
            <button
              className="link-button"
              onClick={async () => {
                await signUserOut();
                saveIsLoggedIn(false);
                fetchUserCredits();
              }}
            >
              Sign Out
            </button>
          </div>
        )}
      </div>

      {showSignUp && (
        <SignUpForm
          show={showSignUp}
          onClose={() => {
            setShowSignUp(false);
          }}
          openSignIn={() => {
            setShowSignUp(false);
            setShowSignIn(true);
          }}
          setIsLoggedIn={setIsLoggedIn}
          fetchUserCredits={fetchUserCredits} 
          setNotificationMessage={setNotificationMessage} 
          setNotificationShown={setNotificationShown} 
        />
      )}

      {showSignIn && (
        <SignInForm 
          show={showSignIn} 
          onClose={() => setShowSignIn(false)} 
          openSignUp={() => { setShowSignIn(false); setShowSignUp(true); }} 
          setIsLoggedIn={setIsLoggedIn} 
          fetchUserCredits={fetchUserCredits} 
        />
      )}

      {notificationMessage && (
        <Notification
          message={notificationMessage}
          notificationShown={notificationShown}
          setNotificationShown={setNotificationShown}
        />
      )}

      <ChatContainer
        isDarkMode={isDarkMode}
        ref={ChatContainerRef} // pass the reference as a prop
        isMobile={isMobile}
        setUserCredits={setUserCredits}
        setIsLoggedIn={setIsLoggedIn}
        showUpgradeMessage={showUpgradeMessage}
        setShowUpgradeMessage={setShowUpgradeMessage}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        openSignIn={openSignIn}
        openSignUp={openSignUp}
        windowWidth={windowWidth}
        userRefdb={userRefdb}
        userData={userData}
        currentUser={currentUser}
        update={update}
        Ampersandposium={Ampersandposium}
        prompt={prompt}
        gpt4={gpt4}
        display={display}
        reloadData={reloadData}
        showDelete={showDelete}
      />
    </div>
  </div></Router>
  );
}

export default App;



