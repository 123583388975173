import React, { useState, useEffect } from 'react';
import Modal from './RoodModal';
import './App.css'; 
import styled from 'styled-components';
// Styled Components
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; // Ensures that your column widths are respected

  th, td {
    padding: 10px;
    border: 1px solid #e0e0e0;
    text-align: center;
    vertical-align: middle;
    overflow: hidden; // Hide overflow content
    text-overflow: ellipsis; // Show ellipsis for overflow text
    white-space: nowrap; // Keep the content in a single line
  }

  tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  tr:hover {
    background-color: #eef6fc;
  }

  th:nth-child(1), td:nth-child(1) {
    width: 5%; // Assigning width for the 'Item' column
  }

  th:nth-child(2), td:nth-child(2) {
    width: 10%; // Assigning width for the 'Created' column
  }

  th:nth-child(3), td:nth-child(3) {
    width: 60%; // Assigning width for the 'Data' column
  }

  th:nth-child(4), td:nth-child(4) {
    width: 5%; // Assigning width for the 'Delete' column
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    th, td {
      padding: 5px; // Reduced padding
      font-size: 14px; // Reduced font size
    }

    th:nth-child(1), td:nth-child(1) {
      width: 10%; 
    }
  
    th:nth-child(2), td:nth-child(2) {
        display: none;
    }
  
    th:nth-child(4), td:nth-child(4) {
      width: 10%; 
    }

  }
`;

const StyledTableContainer = styled.div`
    padding: 20px;

    @media (max-width: 768px) {
    padding: 0px; // Reduced padding for mobile view
    }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const PaginationButton = styled.button`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
    margin-right: 5px; // Less margin on mobile
  }
`;

const PageIndicator = styled.span`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
  }
`;

const JumpToInput = styled.input`
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
  }
`;

const PaginationSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
    margin-top: 10px; // Add space between sections on mobile
  }
`;

const transformData = (incomingData) => {
    if (!incomingData || !Array.isArray(incomingData)) return [];
    return incomingData.map(item => ({
        id: item.metadata.item_id,
        name: item.metadata.name,
        created: item.metadata && (item.metadata.created || item.metadata.created_at) 
                 ? (item.metadata.created || item.metadata.created_at).split('T')[0] 
                 : '',
        data: item.data
    }));
};

const fetchItems = async (itemIds, Ampersandposium) => {
    try {
      console.log("fetchItems:", Ampersandposium)
      console.log(itemIds);
  
      const response = await fetch('https://api.vectorvault.io/get_items', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          vault: Ampersandposium,
          user: process.env.REACT_APP_VV_USER,
          api_key: process.env.REACT_APP_VV_API,
          item_ids: itemIds
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log(responseData);
      return transformData(responseData); // Access the 'results' key and return transformed data
    } catch (error) {
        console.error("There was an error fetching the data:", error);
        throw error; // We re-throw the error so it can be caught and handled by the caller
    }
};

const fetchTotalItems = async (Ampersandposium) => {
    try {
      const response = await fetch('https://api.vectorvault.io/get_total_items', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          vault: Ampersandposium,
          user: process.env.REACT_APP_VV_USER,
          api_key: process.env.REACT_APP_VV_API
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      return responseData; // Assuming the response JSON directly contains the total items count
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

const fetchSearchResults = async (Ampersandposium, searchQuery) => {
    try {
      const response = await fetch('https://api.vectorvault.io/get_similar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          vault: Ampersandposium,
          user: process.env.REACT_APP_VV_USER,
          api_key: process.env.REACT_APP_VV_API,
          openai_key: process.env.REACT_APP_OPEN_API_KEY,
          text: searchQuery
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log(responseData);
      return transformData(responseData); // Assuming the response JSON directly contains the total items count
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

const deleteItem = async (Ampersandposium, item_id) => {
    try {
      const response = await fetch('https://api.vectorvault.io/delete_items', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          vault: Ampersandposium,
          user: process.env.REACT_APP_VV_USER,
          api_key: process.env.REACT_APP_VV_API,
          item_id: item_id
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      return responseData; // Assuming the response JSON directly contains the total items count
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};


// Main Component
function DataTable({ Ampersandposium, reloadData, showDelete }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentData, setCurrentData] = useState('');
    const [currentItemId, setCurrentItemId] = useState(null);
    const [currentCreatedDate, setCurrentCreatedDate] = useState('');
    const [name, setName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [databaseData, setDatabaseData] = useState([])
    const [totalItems, setTotalItems] = useState(0);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [desiredPage, setDesiredPage] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");

    const handlePage = (newPage) => {
        setCurrentPage(newPage);
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const total = await fetchTotalItems(Ampersandposium);
                setTotalItems(total);
                setItemsPerPage(total < 10 ? total : 10);
            } catch (error) {
                console.error("Error fetching initial data:", error);
            }
        };
        
        fetchData();
    }, [Ampersandposium, reloadData]);
    
    useEffect(() => {
        // Ensure totalItems has been fetched before proceeding
        if (totalItems === 0) {
            setIsDataLoading(false);  // <-- Set loading to false when totalItems is 0
            return;
        }
    
        // Generate item IDs for the current page based on currentPage
        const startId = (currentPage - 1) * itemsPerPage;
        const endId = Math.min(startId + itemsPerPage, totalItems);
        const currentPageItemIds = Array.from({ length: endId - startId }, (_, i) => startId + i);
        console.log("currentPageItemIds:", currentPageItemIds);
    
        const fetchPageData = async () => {
            try {
                const dataItems = await fetchItems(currentPageItemIds, Ampersandposium);
                setDatabaseData(dataItems);
                setIsDataLoading(false);
            } catch (error) {
                console.error("Error fetching data for the current page:", error);
            }
        };
        
        fetchPageData();
    }, [currentPage, itemsPerPage, Ampersandposium, totalItems]);

    const handleDataClick = (item) => {
        setCurrentItemId(item.id);
        setCurrentCreatedDate(item.created);
        setName(item.name);
        setCurrentData(item.data);
        setIsModalOpen(true);
    };

    const handleSearch = async () => {
        try {
          setIsDataLoading(true);
          const searchResults = await fetchSearchResults(Ampersandposium, searchQuery);
          setDatabaseData(searchResults);
          setIsDataLoading(false);
        } catch (error) {
          console.error("Error searching for:", searchQuery, error);
        }
      };      

    const handleDelete = async (itemId) => {
        try {
            setIsDataLoading(true)
            await deleteItem(Ampersandposium, itemId);
            console.log('im after delete')
            setCurrentPage(prevPage => prevPage);
            console.log('im after setpage')
            setIsDataLoading(false)
            alert("Item deleted.")
            window.location.reload();
        } catch (error) {
            console.error(`Error deleting item with ID: ${itemId}`, error);
        }
    };

    return (
        <StyledTableContainer>
            {isDataLoading ? (
                // Render the loading spinner when isDataLoading is true
                <div className="loading-spinner" style={{ display: 'block' }}>
                    <svg viewBox="0 0 50 50">
                        <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                    </svg>
                </div>
            ) : (
                <>
            <h2 style={{marginTop: "70px"}}>
            Database: <span>{totalItems} items</span>
            <div style={{ display: 'block', width: '100%', marginBottom: '10px' }}>
                <input
                    type="text"
                    placeholder="Search the database..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ width: 'calc(100% - 16px)', marginRight: '10px', fontSize: "14px"}}
                />
                <button onClick={handleSearch} style={{fontSize: "14px"}}>Search</button>
            </div>
            </h2>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Created</th>
                        <th>Data</th>
                        {showDelete && <th>Delete</th>}
                    </tr>
                </thead>
                <tbody>
                    {databaseData.map((item, index) => (
                        item ? (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.created}</td>
                                <td style={{ textAlign: 'left' }}>
                                    <span onClick={() => handleDataClick(item)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                        {item.data && item.data.length > 50 ? item.data.substring(0, 100) + "..." : item.data}
                                    </span>
                                </td>
                                    {showDelete && (
                                        <td>
                                            <span 
                                                onClick={() => handleDelete(item.id)}
                                                style={{ cursor: 'pointer', color: 'red' }}
                                            >
                                                🗑️
                                            </span>
                                        </td>
                                    )}
                            </tr>
                        ) : null
                    ))}
                </tbody>
            </StyledTable>
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                itemId={currentItemId} 
                createdDate={currentCreatedDate} 
                name={name} 
                content={currentData} 
                Ampersandposium={Ampersandposium} 
            />
            
            {/* Pagination controls */}
            <PaginationContainer>
            <div>
                <PaginationButton 
                onClick={() => handlePage(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                >
                Previous
                </PaginationButton>
                <PageIndicator>
                Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)}
                </PageIndicator>
                <PaginationButton 
                onClick={() => handlePage(Math.min(currentPage + 1, Math.ceil(totalItems / itemsPerPage)))}
                disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                >
                Next
                </PaginationButton>
            </div>
            <PaginationSection>
                Jump to page: 
                <JumpToInput 
                type="number" 
                value={desiredPage}
                onChange={e => setDesiredPage(Number(e.target.value))}
                />
                <PaginationButton 
                onClick={() => setCurrentPage(Math.max(1, Math.min(desiredPage, Math.ceil(totalItems / itemsPerPage))))}
                >
                Go
                </PaginationButton>
            </PaginationSection>
            </PaginationContainer>

    
            {/* Bottom of Page Spacing */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            </>
        )}
        </StyledTableContainer>
    );
}


export default DataTable;
