import React, { useState, forwardRef, useCallback } from 'react';
import { darkStyles, lightStyles } from './styles.js';
import { detectLanguage } from './detectlang.js';
import { AceEditorComponent } from './AceSpaceBro.js';
import { RenderCopyToClipboardButton } from './copyclip.js';
import DataTable from './DataTable';
import './App.css';


export function ChatContainer({ isDarkMode, isMobile, setUserCredits, setIsLoggedIn, showUpgradeMessage, setShowUpgradeMessage, chatHistory, setChatHistory, openSignIn, openSignUp, gpt4, windowWidth, userRefdb, userData, currentUser, update, Ampersandposium, prompt, display, reloadData, showDelete }, ref) {
  const [chatMessage, setChatMessage] = useState('');
  const [isChatLoading, setChatIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [summary, setSummary] = useState(false);
  const [getContext, setGetContext] = useState(true);
  const [nContext, setNContext] = useState(4);
  const [historySearch, setHistorySearch] = useState(false);
  const [model, setModel] = useState('gpt-4');
  const [tempMessage, setTempMessage] = useState('');
  const [includeContextMeta, setIncludeContextMeta] = useState(false);
  const styles = isDarkMode ? darkStyles : lightStyles;
  
  const copyToClipboard = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }
  
  const showChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(true);
  }, [setChatIsLoading]);
  
  const hideChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(false);
  }, [setChatIsLoading]);
  
  const appendChatMessage = useCallback((message, isUserMessage) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { message, isUserMessage },
    ]);
  }, [setChatHistory]);

  const removeLastChatMessage = useCallback(() => {
    setChatHistory((prevHistory) => {
      // Check if the last message is a user message
      if (prevHistory.length > 0 && !prevHistory[prevHistory.length - 1].isUserMessage) {
        // Remove the last item from the array if it's not a user message
        const updatedHistory = prevHistory.slice(0, prevHistory.length - 1);
        return updatedHistory;
      }
      // Return the original chat history if the last message is a user message
      return prevHistory;
    });
  }, [setChatHistory]);
  
  function renderChatSegment(segment, index, isUserMessage) {
    const ram = '```';
    if (segment.startsWith(ram) && segment.endsWith(ram)) {
      const code = segment.slice(3, -3);
      const type = detectLanguage(code)
      
      const increaseBy = 1/(windowWidth/500) * 6
      
      return (
        <div key={index} style={{ position: 'relative', width: `10${increaseBy}%`, marginLeft: '-13px', }}>
        <label>{type}</label>
        <AceEditorComponent code={code} type={type} isDarkMode={isDarkMode} />
        {RenderCopyToClipboardButton(code, isDarkMode, isCopied, setIsCopied, copyToClipboard)}
    </div>
      );
    } else {
      return (
        <div key={index} id="chat-history" style={isUserMessage ? styles.userMessage : styles.botMessage}>
          {segment}
        </div>
      );
    }
  }
  
  function UpgradeMessage() {
    return (
      <div>
        <p>Serving PhilosophyGPT isn't free. Sign up to continue:</p>
          <stripe-buy-button
            buy-button-id="buy_btn_1MrtDZET5bakQRi3AO3mk56T"
            publishable-key={process.env.REACT_APP_STRIPE_PUB_KEY}
            ></stripe-buy-button>
      </div>
    );
  }


  const sendChatMessage = async () => {
    console.log('sendChatMessage invoked');
    if (!chatMessage) return;
    appendChatMessage(chatMessage, true);
    showChatLoadingSpinner();
    let pastChatMessage = chatMessage
    let metatag, metatag_prefixes, metatag_suffixes, returnContext;
    setChatMessage('');
    
    if (pastChatMessage.trim().endsWith('**')) {
      metatag = ['item_id'];
      metatag_prefixes = ['\n**Item ID:'];
      metatag_suffixes = ['\n'];
      returnContext = true;
    } else {
      metatag = [];
      metatag_prefixes = [];
      metatag_suffixes = [];
      returnContext = false;
    }

    if (gpt4 === true) {
      setModel('gpt-4-1106-preview')
    }
    
    let fullHistory = chatHistory.map((item) => {
      return item.isUserMessage ? `User: ${item.message}` : `Response: ${item.message}`;
    }).join('\n');
    
    let creditsUsed = (fullHistory.length + pastChatMessage.length) / 2000;
    if (creditsUsed >= 10) {
      creditsUsed = 10;
    }

    let buffer = '';
    try {
      setIsLoggedIn(Boolean(currentUser));
      if (userData.credits >= 5) {
        fetch('https://api.vectorvault.io/stream', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            vault: Ampersandposium,
            user: process.env.REACT_APP_VV_USER,
            api_key: process.env.REACT_APP_VV_API,
            openai_key: process.env.REACT_APP_OPEN_API_KEY,
            text: pastChatMessage,
            history: fullHistory,
            summary: summary,
            get_context: getContext,
            n_context: nContext,
            return_context: returnContext,
            history_search: historySearch,
            model: model,
            include_context_meta: includeContextMeta,
            metatag: metatag,
            metatag_prefixes: metatag_prefixes,
            metatag_suffixes: metatag_suffixes,
            custom_prompt: prompt
          })
        }).then(response => {
          let reader = response.body.getReader();
          let decoder = new TextDecoder();
  
          return reader.read().then(function processText({ done, value }) {
            console.log({ done, value });
            if (done) return;
            let result = decoder.decode(value, {stream: true});
  
            if (result.includes('data: ')) {
              if (showChatLoadingSpinner) {
                hideChatLoadingSpinner();
              }
              let dataBlocks = result.split('data: ').filter(Boolean);
              dataBlocks.forEach((dataBlock) => {
                buffer += dataBlock; // Append new data to buffer
                try {
                  // Try to parse the entire buffer each time new data comes
                  let parsedData = JSON.parse(buffer);
                  let formattedData = parsedData.data;
                  
                  // If parsing is successful, the buffer contains a complete JSON object
                  // Clear the buffer for the next object
                  buffer = '';
                  
                  // Process the complete object
                  if (formattedData === '!END') {
                    reader.cancel();
                    formattedData = '';
                    setTempMessage(tempMessage => {
                      const newChatHistory = [...chatHistory, { message: pastChatMessage, isUserMessage: true }, { message: tempMessage, isUserMessage: false }];
                      localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
                      setChatHistory(newChatHistory);
                      const usedUp = creditsUsed + (tempMessage.length / 200);
                      const newCreds = userData.credits - usedUp;
                      update(userRefdb, { credits: newCreds });
                      setUserCredits(1000);
                      return '';
                    });
                  } else {
                    setTempMessage(tempMessage => {
                      let updatedTempMessage;
                      if (formattedData === '!END') {
                        updatedTempMessage = `${tempMessage}`;
                      } else {
                        updatedTempMessage = `${tempMessage}${formattedData}`;
                        removeLastChatMessage();
                        appendChatMessage(updatedTempMessage, false);
                      }
                      return updatedTempMessage;
                    });
                  }
                } catch (err) {
                  // If parsing fails, the JSON object is probably incomplete and has been split between data events
                  // The catch block will catch the error and the buffer will keep the data until the rest of it arrives in the next event(s)
                  console.log('Incomplete JSON object in buffer. Waiting for more data.');
                }
              });
            }
            return reader.read().then(processText);
          });
          
        }).catch(err => {
          console.error(err);
        });
      } else {
        if (currentUser) {
          setShowUpgradeMessage(true);
          localStorage.setItem("showUp", "true");
        } else {
          appendChatMessage(`Insufficient credits. Sign in or Sign up at the top to continue.`, false);
          setShowUpgradeMessage(false);
          localStorage.setItem("showUp", "false");
          hideChatLoadingSpinner();
        }
      }
    } catch (error) {
      appendChatMessage(error.message, false);
      localStorage.setItem("chatHistory", JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }]));
    } finally {
    }
  };

  
  const clearChat = useCallback(() =>  {
    setChatHistory([])
    localStorage.removeItem("chatHistory");
    localStorage.setItem("showUp", "false");
    setShowUpgradeMessage(false);
  }, [setShowUpgradeMessage, setChatHistory]);
  
  const handleTextareaKeydown = (e) => {
    if  (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      sendChatMessage();
    }
  };
  
  return (
    <>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px", marginLeft: "-12px"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <h3 style={{ ...styles.responseContainerH3, marginLeft: "-6px"}}>Chat:</h3>
          <button id="clear-chat" className="link-button" onClick={clearChat} style={{ marginTop: "-20px" }}>Clear</button>
          {showUpgradeMessage && <UpgradeMessage />}
        </div>
        {chatHistory.flatMap((item, index) => {
            const segments = item.message.split(/(```[\s\S]*?```)/);
            return segments.map((segment, segmentIndex) =>
              renderChatSegment(segment, `${index}-${segmentIndex}`, item.isUserMessage)
            );
          })}
        <label htmlFor="chat-message" style={{ ...styles.yourmsgLabel, marginLeft: "-4px"}}>
          Your message:
        </label>
        <textarea //
          placeholder={display ? `Type your message here... (add ** at the end to see context)` : `Type your question here...` }
          id="chat-message"
          rows={isMobile ? "8" : "5" }
          style={{ ...styles.textarea, marginLeft: "-8px", paddingRight: "6px",paddingLeft: "8px"}}
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}> 
        <button id="chat-submit" style={{ ...styles.button, marginLeft: "-6px"}} onClick={sendChatMessage}>Send</button>
        <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isChatLoading ? 'block' : 'none', paddingLeft: '20px'  }}>
          <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
          </svg>
        </div>
      </div>
        {display && <DataTable Ampersandposium={Ampersandposium} reloadData={reloadData} showDelete={showDelete} />}
      </div>
    </>
  );
}

export default forwardRef(ChatContainer);
/** 
magick -background none -size 960x960 xc:black ( xc:darkred -duplicate 1 +append ) xc:gold ( xc:teal -duplicate 2 +append ) -modulate 100,100,"%[fx:rand()*200]" xc:white -scale x1 +append -write mpr:clut +delete radial-gradient: mpr:clut -clut -scale 100x4% -wave "%[fx:rand()*24+24]"x"%[fx:w/ceil(rand()*4+1)]" -extent "%[w]x%[w]" -roll +0+"%[fx:(rand()*w*0.05)+(w*0.51)]" ( +clone -blur 0x4 ) -insert 0 -composite -duplicate "%[fx:floor(rand()*3+3)*2-1]" -set option:rot "%[fx:180/n]" -virtual-pixel tile -virtual-pixel none -distort SRT "%[fx:t*360/n]" +repage -flatten -extent 100x50% ( +clone -rotate 180 ) -append +channel -virtual-pixel none -distort SRT "0.96 %[fx:rand()*360]" ( +clone -flop ) +repage -insert "%[fx:round(rand())]" -background black -flatten -brightness-contrast 20,20 -normalize dragonFire.png
*/